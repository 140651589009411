import React, {useState} from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import {observer} from "mobx-react-lite";
import {CircularProgress, FormControl, MenuItem, Select} from "@mui/material";
import {useStore} from "../../../hooks/useStore";
import PasswordModal from "../../../components/dialog_modal/passwordmadal";

const Header = observer(({ageGroupSelect, setAgeGroupSelect}) => {
    const store = useStore()

    const [visualReloadData, setVisualReloadData] = useState(false);
    const [visualUploadOrder, setVisualUploadOrder] = useState(false);
    const [visualUploadCoachCard, setVisualUploadCoachCard] = useState(false);
    const [visualRemoveCompetition, setVisualRemoveCompetition] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [password, setPassword] = React.useState(0);

    const handleReloadData = async () => {
        setVisualReloadData(true);
        await store.setting_sorev.reloadUsersData();
        setVisualReloadData(false);
    }
    const handleUploadOrder = async () => {
        setVisualUploadOrder(true);
        await store.setting_sorev.uploadOrder();
        setVisualUploadOrder(false);
    }

    const handleUploadCoachCard = async () => {
        setVisualUploadCoachCard(true);
        await store.setting_sorev.uploadCoachCard();
        setVisualUploadCoachCard(false);
    }

    const removeCompetition = async () => {
        setVisualRemoveCompetition(true);
        setOpenDialog(false);
        await store.setting_sorev.removeCompetition(password);
        setVisualRemoveCompetition(false);
    }


    return (
        <div className={s.header}>
            <Wrapper>
                <div className={s.header_split}>
                    <div className={s.header_data}>
                        <FormControl fullWidth sx={{
                            maxWidth: '254px',
                            height: '45px'
                        }}>
                            <Select
                                placeholder={'Возрастная группа'}
                                displayEmpty
                                value={ageGroupSelect}
                                onChange={(e) => {
                                    setAgeGroupSelect(e.target.value)
                                }}
                                inputProps={{'aria-label': 'Without label'}}
                                sx={{
                                    height: '45px',
                                    backgroundColor: '#fff'
                                }}
                                labelId="demo-simple-select-label"
                            >

                                <MenuItem sx={{
                                    display: 'none'
                                }} hidden disabled selected value={null}>Возрастная группа</MenuItem>
                                {store.distipline_settings.age_group.map((el, i) => <MenuItem key={el.age_group_id}
                                                                                              value={el.age_group_id}>{`${el.age_group_name}`}</MenuItem>)}

                            </Select>
                        </FormControl>

                        <div>
                            <h1 className={s.name}>{store?.global?.status?.competition_name || ''}</h1>
                        </div>
                    </div>

                </div>

            </Wrapper>
            <div className={s.wrappen_btns_reload}>

                <button disabled={visualReloadData} className={s.btn_reload} onClick={handleReloadData}>
                    Обновить данные физлиц
                    {visualReloadData &&
                        <CircularProgress style={{position: "absolute", marginLeft: "130px", marginTop: "20px"}}
                                          size="12px" color="inherit"/>}
                </button>
                <button disabled={visualUploadOrder} className={s.btn_reload} onClick={handleUploadOrder}>
                    Загрузить заявки
                    {visualUploadOrder &&
                        <CircularProgress style={{position: "absolute", marginLeft: "130px", marginTop: "20px"}}
                                          size="12px" color="inherit"/>}
                </button>
                <button disabled={visualUploadCoachCard} className={s.btn_reload} onClick={handleUploadCoachCard}>
                    Обновить карточки тренера
                    {visualUploadCoachCard &&
                        <CircularProgress style={{position: "absolute", marginLeft: "130px", marginTop: "20px"}}
                                          size="12px" color="inherit"/>}
                </button>
                <button disabled={visualRemoveCompetition} className={s.btn_remove} onClick={() => setOpenDialog(true)}>
                    Очистить соревнование
                    {visualRemoveCompetition &&
                        <CircularProgress style={{position: "absolute", marginLeft: "130px", marginTop: "20px"}}
                                          size="12px" color="inherit"/>}
                </button>
            </div>

            <PasswordModal
                title="Вы действительно хотите очистить соревнование?"
                open={openDialog} setOpen={setOpenDialog} setPassword={setPassword} handlerConfirm={removeCompetition}/>
        </div>
    );
});

export default Header;
