import React, {useEffect, useRef, useState} from 'react';
import s from './styles.module.css'
import Header from "./0-header";
import Commands from "./1-commands";
import Table from "./2-table";
import {useStore} from "../../hooks/useStore";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {toast} from "react-toastify";
import {Button, DialogContent, MenuItem} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import classNames from "classnames";
import SettingsDistiplines from "../setting_distiplines";
import SettingsSorev from "../settings_sorev";
import {observer} from "mobx-react-lite";
import FigureBox from "./figure_box";
import Report from "../report";
import {SOCKET_API_URL} from "../../constants";
import AlertDialogCustomButton from "../../components/dialog_modal/custom_button"
import api from "../../api/api";

const headers = [
    {id: 1, title: 'Хронометраж'},
    {id: 2, title: 'Настройка дисциплин'},
    {id: 3, title: 'Настройка соревнования'},
    {id: 4, title: 'Отчеты'},
]
const Judging = observer(() => {
    const store = useStore()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isReferee = localStorage.getItem('role') === 'referee';

    const catMenu = useRef(null)

    const [showMenu, setShowMenu] = useState(false)
    const [headerSelect, setHeaderSelect] = useState(1)
    const [statusInfo, setStatusInfo] = useState()
    const [selectedCommand, setSelectedCommand] = useState(null)
    const [select, setSelect] = useState(null)
    const [showMoreCommand, setShowMoreCommand] = useState(false)
    const [ageGroupDisciplines, setAgeGroupDisciplines] = useState([])
    const [commands, setCommands] = useState([])
    const [scoreCommand, setScoreCommand] = useState(null)
    const [judgleStatus, setJudgleStatus] = useState(null)
    const [socketData, setSocketData] = useState(null)
    const [ids, setIds] = useState({
        id_group: null, id_disciplin: null, discipline_stage_id: null
    })
    const [openDialogCustom, setOpenDialogCustom] = React.useState(false);
    const [coachTypeId, setCoachTypeId] = React.useState(0);
    const [coachTypeNumber, setCoachTypeNumber] = React.useState(0);

    const selectedCommandObject = commands?.find((f) => f.competition_application_discipline_id === selectedCommand) || {}

    const movingStatusNextOrPrev = async (type) => {
        await store.judging.movingStatusNextOrPrev(type)
        await store.judging.getAllCommand(ids?.id_group, ids?.id_disciplin, ids?.discipline_stage_id)
        setCommands(store.judging.commands)
        setStatusInfo(JSON.parse(JSON.stringify(store.judging.status)))
    }

    const getJudgleStatus = async () => {
        await store.judging.getJudgleStatus()
        setJudgleStatus(JSON.parse(JSON.stringify(store.judging.judgleStatus)))
    }

    const choiceJudge = async () => {
        await api().get(`device_choice/${coachTypeId}/${coachTypeNumber}`).then((res) => {
            if (res?.data?.status === 'ok') {
                toast.success('Выбор судьи на устройстве')
            }
            if (res?.data?.status === 'error') {
                toast.error(res?.data?.msg)
                setOpenDialogCustom(false)
            }
        })
        setOpenDialogCustom(false)

        await getJudgleStatus()
    }

    const resetJudgeSession = async () => {

        await api().get(`device_kill_session/${coachTypeId}/${coachTypeNumber}`).then((res) => {
            if (res?.data?.status === 'ok') {
                toast.success('Сброс устройства')
            }
            if (res?.data?.status === 'error') {
                toast.error(res?.data?.msg)
                setOpenDialogCustom(false)
            }
        })
        setOpenDialogCustom(false)

        await getJudgleStatus()
    }

    const startJudging = async () => {
        const currentGroupDiscipline = ageGroupDisciplines.find((f) => f.id === select)
        // console.log(ids)
        await store.judging.startJudging(ids?.id_group, ids?.id_disciplin, ids?.discipline_stage_id)
        await store.judging.getAllCommand(ids?.id_group, ids?.id_disciplin, ids?.discipline_stage_id)
        setCommands(store.judging.commands)
        setStatusInfo(JSON.parse(JSON.stringify(store.judging.status)))
    }

    const stopJudging = async () => {
        await store.judging.stopJudging()
        await store.judging.getAllCommand(ids?.id_group, ids?.id_disciplin, ids?.discipline_stage_id)
        setCommands(store.judging.commands)
        setStatusInfo(JSON.parse(JSON.stringify(store.judging.status)))
    }

    const getAllCommand = async (id_group, id_disciplin, idDisciplineStage) => {
        setIds({id_group, id_disciplin, discipline_stage_id: idDisciplineStage})
        setSelectedCommand(null)
        await store.judging.getAllCommand(id_group, id_disciplin, idDisciplineStage)
        setCommands(store.judging.commands)
    }

    const getScoreCommand = async (id) => {
        await store.judging.getScoreCommand(id)
        setScoreCommand(store.judging.scoreCommand)
    }

    const setClearScore = async (showUpdate = true) => {
        await store.judging.setClearScore(selectedCommand, showUpdate);
        setScoreCommand(store.judging.scoreCommand)
    }

    const setNewScore = async (id, value, showUpdate = true) => {
        await store.judging.setNewScore(selectedCommand, id, value, showUpdate, selectedCommand)
        setScoreCommand(store.judging.scoreCommand)
    }

    const setNewListScore = async (listScore, setListScore, showUpdate = true) => {
        await store.judging.setNewListScore(selectedCommand, listScore, showUpdate, selectedCommand).then(() => {
            setListScore([])
        })
        setScoreCommand(store.judging.scoreCommand)
    }

    const doubleStartJudging = async (id) => {
        await store.judging.doubleStartJudging(id)
        await store.judging.getAllCommand(ids?.id_group, ids?.id_disciplin, ids?.discipline_stage_id)
        setCommands(store.judging.commands)
        setStatusInfo(JSON.parse(JSON.stringify(store.judging.status)))
    }

    useEffect(() => {
        const getJudgleStatus = async () => {
            await store.judging.getJudgleStatus()
            setJudgleStatus(JSON.parse(JSON.stringify(store.judging.judgleStatus)))
        }
        getJudgleStatus()
    }, [])

    useEffect(() => {
        const distiplins = ageGroupDisciplines.find((f) => f.id === select)

        if (statusInfo?.competition_application_discipline_id && (distiplins?.discipline_id === statusInfo?.discipline_id && distiplins?.age_group_id === statusInfo?.age_group_id)) {
            getScoreCommand(statusInfo?.competition_application_discipline_id)
            setSelectedCommand(statusInfo?.competition_application_discipline_id)
        }
    }, [statusInfo, select])

    useEffect(() => {
        const getStatusJudging = async () => {
            await store.judging.statusJudging()
            setStatusInfo(JSON.parse(JSON.stringify(store.judging.status)))
        }

        getStatusJudging()
    }, [])

    useEffect(() => {
        const getAgeGroupDiscipline = async () => {
            await store.judging.getAgeGroupDiscipline()
            setAgeGroupDisciplines(store.judging.age_group_disciplines)
            // console.log(JSON.parse(JSON.stringify(store.judging.age_group_disciplines)))
        }
        getAgeGroupDiscipline()
    }, [])

    useEffect(() => {
        let socket = new SockJS(`${SOCKET_API_URL}/socket`);
        let stompClient = Stomp.over(socket);

        socket.onopen = () => {
        }
        stompClient.debug = null

        stompClient.connect({}, function (frame) {
            stompClient.subscribe('/topic/events', async function (greeting) {
                const res = JSON.parse(greeting.body)

                if (res.method === 'update_device') {
                    toast.warning(res.msg, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    const getJudgleStatus = async () => {
                        await store.judging.getJudgleStatus()
                        setJudgleStatus(JSON.parse(JSON.stringify(store.judging.judgleStatus)))
                    }
                    getJudgleStatus()
                }

                if (res.method === 'hand') {
                    setSocketData(res)
                }
                if (res.method === 'update_scores') {
                    setSocketData(res)
                }
                if (res.method === 'update') {
                    setSocketData(res)
                }
                if (res.method === 'update_user_figure') {
                    setSocketData(res)
                }
                if (res.method === 'switch') {
                    setSocketData(res)
                }
                // console.log(res)

            });

        })
    }, [])

    useEffect(() => {
        const updateData = async () => {
            if (ids?.id_group === null || ids?.id_disciplin === null || ids?.discipline_stage_id === null) return
            await store.judging.getAllCommand(ids?.id_group, ids?.id_disciplin, ids?.discipline_stage_id)
            setCommands(store.judging.commands)

            await store.judging.statusJudging()
            setStatusInfo(JSON.parse(JSON.stringify(store.judging.status)))
        }
        if (socketData?.method === 'switch') {
            updateData()
        }
    }, [socketData])
    const closeOpenMenus = (e) => {
        if (catMenu.current && showMenu && !catMenu.current.contains(e.target)) {
            setShowMenu(false)
        }
    }


    document.addEventListener('mousedown', closeOpenMenus)

    return (
        <div className={s.judging_wrapper}>
            <AlertDialogCustomButton
                open={openDialogCustom} setOpen={setOpenDialogCustom} handlerRedButton={resetJudgeSession}
                handlerBlueButton={choiceJudge}
                redButton={'Сброс сессии устройства'}
                blueButton={'Перейти к выбору судьи'}
                couchTypeId={coachTypeId}
                couchTypeNumber={coachTypeNumber}
            />
            {<div className={s.select} onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowMenu(!showMenu)
            }} ref={catMenu}>
                <div className={s.select_line}/>
                <div className={s.select_line}/>
                <div className={s.select_line}/>

                {showMenu && <div className={s.select_menu}>
                    {!isReferee && headers.map((el) => <div key={el.id} onClick={() => setHeaderSelect(el.id)}
                                              className={classNames(s.select_menu_item, el.id === headerSelect && s.selected_item)}>{el.title}</div>)}
                    <div className={classNames(s.select_menu_item,)} style={{
                        color: '#e16565'
                    }} onClick={() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem("role")
                        window.location.reload();
                    }}>
                        Выход
                    </div>
                </div>}

            </div>}

            {(socketData !== null && socketData?.method === 'hand') && <Dialog
                open={socketData !== null}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {socketData.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSocketData(null)}>OK</Button>
                </DialogActions>
            </Dialog>}
            {headerSelect === 1 && <Header isReferee={isReferee} movingStatusNextOrPrev={movingStatusNextOrPrev}
                                           startJudging={startJudging}
                                           stopJudging={stopJudging} statusInfo={statusInfo} setSelect={setSelect}
                                           selectedCommand={selectedCommand}
                                           age_group_id={ids.id_disciplin}
                                           nameCommand={commands?.find((f) => f?.competition_application_discipline_id === selectedCommand)?.competition_application_name}
                                           select={select}
                                           ageGroupDisciplines={ageGroupDisciplines}
                                           setAgeGroupDisciplines={setAgeGroupDisciplines}
                                           getAllCommand={getAllCommand} socketData={socketData}/>}
            {(ids.id_disciplin !== 1) ? <>
                {headerSelect === 1 && <>
                    <Commands isReferee={isReferee} doubleStartJudging={doubleStartJudging} statusInfo={statusInfo}
                              getScoreCommand={getScoreCommand}
                              setSelectedCommand={setSelectedCommand}
                              selectedCommand={selectedCommand} select={select} socketData={socketData}
                              commands={commands} setShowMoreCommand={setShowMoreCommand}
                              showMoreCommand={showMoreCommand}/>
                    <Table setClearScore={setClearScore} isReferee={isReferee} judgleStatus={judgleStatus}
                           setNewScore={setNewScore}
                           setNewListScore={setNewListScore}
                           selectedCommandObject={selectedCommandObject}
                           setJudgleStatus={setJudgleStatus}
                           scoreCommand={scoreCommand}
                           selectedCommand={selectedCommand} showMoreCommand={showMoreCommand}
                           setOpenDialogCustom={setOpenDialogCustom} setCoachTypeId={setCoachTypeId}
                           setCoachTypeNumber={setCoachTypeNumber}
                    />
                </>}
            </> : (headerSelect === 1 &&
                <FigureBox
                    socketData={socketData}
                    judgleStatus={judgleStatus}
                    id_disciplin={ids.id_disciplin}
                    id_group={ids.id_group}
                    setOpenDialogCustom={setOpenDialogCustom}
                    setCoachTypeId={setCoachTypeId}
                    setCoachTypeNumber={setCoachTypeNumber}
                />)}

            {headerSelect === 2 && <SettingsDistiplines/>}
            {headerSelect === 3 && <SettingsSorev/>}
            {headerSelect === 4 && <Report/>}
        </div>
    );
});

export default Judging;
