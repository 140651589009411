import api from "../../api/api";

class SettingSorevService {
    async getDisciplineLeft(id) {
        const res = await api().get(`general/discipline/${id}`)
        return res
    }

    async saveDisciplineLeft(payload) {
        const res = await api().post(`general/discipline/save`, [...payload])
        return res
    }

    async saveToFinal(age_group_id, discipline_id) {
        const res = await api().get(`general/discipline/final/${age_group_id}/${discipline_id}`)
        return res
    }

    async reloadUsersData() {
        const res = await api().get(`settings/users_reload`)
        return res
    }

    async removeCompetition(password) {
        return await api().get(`general/clear_all_competition/${password}`)
    }

    async uploadCoachCard() {
        const res = await api().get(`competition/coach-card-reload`);
        return res;
    }

    async uploadOrder() {
        const res = await api().get(`competition/upload`)
        return res
    }

    async getStatic(id) {
        const res = await api().get(`general/result/static/${id}`,)
        return res
    }

    async addedDinamic(name) {
        const res = await api().get(`general/result/dinamic/new/${name}`,)
        return res
    }

    async getDinamic(id) {
        const res = await api().get(`general/result/dinamic/${id}`,)
        return res
    }

    async getDiscipline() {
        const res = await api().get(`general/discipline`,)
        return res
    }

    async removeDinamic(id) {
        const res = await api().get(`general/result/dinamic/delete_type/${id}`,)
        return res
    }

    async changeName(name, id) {
        const res = await api().get(`general/result/dinamic/rename/${name}/${id}`,)
        return res
    }

    async addedDiscipline(ds_id, res_id, id) {
        const res = await api().get(`general/result/dinamic/add/${ds_id}/${res_id}/${id}`,)
        return res
    }

    async removeDiscipline(id) {
        const res = await api().get(`general/result/dinamic/delete/${id}`,)
        return res
    }
}

export default new SettingSorevService()
