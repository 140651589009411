import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import {Button, FormControl, MenuItem, Select} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ModalSportsmen from "../../../components/modal_sportsmen";
import AlertDialog from "../../../components/dialog_modal";
import {useStore} from "../../../hooks/useStore";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Header = ({
                    isReferee,
                    age_group_id,
                    socketData,
                    movingStatusNextOrPrev,
                    startJudging,
                    ageGroupDisciplines,
                    setAgeGroupDisciplines,
                    stopJudging,
                    statusInfo,
                    selectedCommand,
                    nameCommand,
                    getAllCommand,
                    setSelect,
                    select
                }) => {
    const store = useStore()
    const [showModal, setShowModal] = useState(false)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogNext, setOpenDialogNext] = React.useState(false);
    const [openDialogPrev, setOpenDialogPrev] = React.useState(false);

    const handlerConfirm = () => {
        if (statusInfo?.status === 'start') {
            stopJudging()
            setOpenDialog(false)
        } else {
            startJudging()
            setOpenDialog(false)
        }
    }

    const downloadJudgle = () => {
        store.judging.downloadJudgle(selectedCommand)
    }


    useEffect(() => {
        const element = ageGroupDisciplines.find((f) => f.id === select)
        if (
            socketData?.method === 'update_scores' &&
            +element?.age_group_id === +socketData?.age_group_id &&
            +element?.discipline_id === +socketData?.discipline_id &&
            +element?.discipline_stage_id === +socketData?.discipline_stage_id
        ) {
            getAllCommand(+socketData?.age_group_id, +socketData?.discipline_id, +socketData?.discipline_stage_id)
        }
    }, [socketData])

    useEffect(() => {
        const getAgeGroupDiscipline = async () => {
            await store.judging.getAgeGroupDiscipline()
            setAgeGroupDisciplines(store.judging.age_group_disciplines)
        }
        getAgeGroupDiscipline();
    }, [])

    return (
        <div className={s.header}>
            <AlertDialog
                title={statusInfo?.status === 'start' ? 'Остановить соревнование ?' : 'Запустить соревнование ?'}
                open={openDialog} setOpen={setOpenDialog} handlerConfirm={handlerConfirm}/>
            <AlertDialog title={store.judging?.check?.msg} open={openDialogPrev} setOpen={setOpenDialogPrev}
                         status={store.judging?.check?.status} actions={store.judging?.check?.answers}
                         handlerConfirm={async (id) => {
                             try {
                                 if (store.judging?.check?.status === 'question') {
                                     const res = await store.judging.confirmConfirm(id, statusInfo.competition_application_discipline_id)
                                 }
                                 movingStatusNextOrPrev('prev')
                                 setOpenDialogPrev(false)
                             } catch (e) {
                                 console.log(e)
                             }
                         }}
            />
            <AlertDialog title={store.judging?.check?.msg} open={openDialogNext} setOpen={setOpenDialogNext}
                         status={store.judging?.check?.status} actions={store.judging?.check?.answers}
                         handlerConfirm={async (id) => {
                             try {
                                 if (store.judging?.check?.status === 'question') {
                                     const res = await store.judging.confirmConfirm(id, statusInfo.competition_application_discipline_id)
                                 }
                                 movingStatusNextOrPrev('next')
                                 setOpenDialogNext(false)
                             } catch (e) {
                                 console.log(e)
                             }
                         }}/>
            {showModal &&
                <ModalSportsmen nameCommand={nameCommand} selectedCommand={selectedCommand} open={showModal}
                                onClose={() => setShowModal(false)}/>}

            <Wrapper>
                <div className={s.header_content}>

                    <FormControl fullWidth sx={{
                        maxWidth: '491px',
                        height: '45px'
                    }}>
                        <Select
                            placeholder={'Возрастная группа + дисциплина'}
                            displayEmpty
                            value={select}
                            onChange={(e) => {
                                const element = ageGroupDisciplines.find((f) => f.id === e.target.value)

                                setSelect(e.target.value)
                                getAllCommand(element.age_group_id, element.discipline_id, element?.discipline_stage_id)
                            }}
                            inputProps={{'aria-label': 'Without label'}}
                            sx={{
                                height: '45px',
                                backgroundColor: '#fff'
                            }}
                            labelId="demo-simple-select-label"
                        >

                            <MenuItem sx={{
                                display: 'none'
                            }} hidden disabled selected value={null}>Возрастная группа + дисциплина</MenuItem>
                            {ageGroupDisciplines.map((el, i) => <MenuItem key={el.id}
                                                                          value={el.id}>{`${el.age_group_name} - ${el.discipline_name}`}</MenuItem>)}

                        </Select>
                    </FormControl>


                    <div className={s.header_middle_box}>
                        <div className={s.choose_select_value} style={{
                            marginRight: isReferee && '0'
                        }}>
                            {statusInfo?.age_group_discipline_short || '...'}
                        </div>
                        {!isReferee && <Button onClick={() => {
                            setOpenDialog(true)
                        }} disabled={select === null && statusInfo?.status !== 'start'} sx={{
                            height: '45px',
                            width: '98px'
                        }} variant="contained" color={statusInfo?.status === 'start' ? "error" : "primary"}>
                            {statusInfo?.status === 'start' ? ' Стоп' : 'Старт'}
                        </Button>}

                        {!isReferee && <>
                            {age_group_id !== 1 ? <div className={s.btns}>
                                    <Button
                                        onClick={() => store.judging.checkJudging(setOpenDialogPrev, statusInfo.competition_application_discipline_id)}
                                        disabled={statusInfo?.status !== 'start'}
                                        sx={{backgroundColor: '#fff', height: '45px', width: '58px', marginRight: '8px'}}
                                        variant="outlined"> <ArrowForwardIosIcon sx={{
                                        rotate: '180deg'
                                    }}/></Button>

                                    <Button
                                        onClick={() => store.judging.checkJudging(setOpenDialogNext, statusInfo.competition_application_discipline_id)}
                                        disabled={statusInfo?.status !== 'start'}
                                        sx={{backgroundColor: '#fff', height: '45px', width: '58px'}}
                                        variant="outlined"> <ArrowForwardIosIcon/></Button>
                                </div> :
                                <div className={s.btns}>
                                    <Button
                                        sx={{opacity: 0, width: '58px', marginRight: '8px', cursor: 'default'}}
                                        variant="outlined"> <ArrowForwardIosIcon sx={{
                                        rotate: '180deg'
                                    }}/></Button>

                                    <Button
                                        sx={{opacity: 0, width: '58px', cursor: 'default'}}
                                        variant="outlined"> <ArrowForwardIosIcon/></Button>
                                </div>}
                        </>}
                    </div>

                    {!isReferee && <>
                        {age_group_id !== 1 ?
                            <Button onClick={() => setShowModal(true)} disabled={selectedCommand === null} sx={{
                                backgroundColor: '#fff',
                                height: '45px',
                                width: '144px'
                            }} variant="outlined">Спортсмены</Button> :
                            <Button sx={{
                                opacity: 0,
                                width: '144px',
                                cursor: 'default'
                            }} variant="outlined">Спортсмены</Button>
                        }
                        <Button onClick={downloadJudgle} disabled={selectedCommand === null} sx={{
                            backgroundColor: '#fff',
                            height: '45px',
                            width: '58px',
                            display: 'none',
                        }} variant="outlined"><FileDownloadIcon/></Button>
                    </>}
                </div>
            </Wrapper>
        </div>
    );
};

export default Header;
