import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from "@mui/material";

export default function PasswordModal({setPassword, setOpen, open, handlerConfirm, title, status = '', actions}) {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogActions style={{marginBottom: '15px'}}>
                    <TextField label="Пароль" variant="outlined" size="small" type={"password"}
                               sx={{marginRight: '80px', marginBottom: '1px'}}
                               onChange={(event) => {
                                   setPassword(event.target.value);
                               }}
                    />
                    <Button variant="contained" color="error" onClick={handleClose}>Отмена</Button>
                    <Button variant="contained" color="primary" onClick={handlerConfirm} autoFocus>Очистить</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
