import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import axios from "axios";

const Auth = ({setIsAuth}) => {
    const [data, setData] = useState({
        login: "",
        password: ""
    })

    const [roles, setRoles] = useState([]);
    const [role, setRole] = React.useState('');
    const [isRoleLoaded, setIsRoleLoaded] = useState(false);

    useEffect(() => {
        const getRoles = async () => {
            setIsRoleLoaded(true);
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL_PORT}authentication/users`);
                setRoles(res.data?.logins);
            } catch (error) {
                console.log(error)
            } finally {
                setIsRoleLoaded(false);
            }
        }

        getRoles();
    }, []);


    const handleLogin = async () => {
        try {
            console.log(data);
            const res = await axios.post(`${process.env.REACT_APP_API_URL_PORT}authentication/authenticate`, {...data})
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('role', res.data.role);
            setIsAuth(true)
        } catch (e) {
            console.log(e)
            toast.error('Ошибка')
        }
        // Обработка логина
    };

    const handleChange = (event) => {
        setRole(event.target.value);
        setData({...data, login: event.target.value});
    };

    return (
        <div className={s.main}>
            <Container xs={{backgroundColor: "red"}} maxWidth="xs">
                <Typography variant="h4" align="center" gutterBottom marginBottom="20px">
                    Авторизация
                </Typography>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    {/*<Grid item xs={12}>*/}
                    {/*    <TextField value={data.login} onChange={(e) => setData({...data, login: e.target.value})}*/}
                    {/*               label="Логин" variant="outlined" fullWidth/>*/}
                    {/*</Grid>*/}
                    <FormControl fullWidth sx={{maxWidth: '396px', height: '55px'}}>
                        <InputLabel sx={{
                            paddingLeft: "10px"
                        }} id="select-label">Роль</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="select-label"
                            value={role}
                            label="Роль"
                            disabled={isRoleLoaded}
                            onChange={handleChange}
                            sx={{
                                marginLeft: "8px",
                                width: '100%',
                                height: '55px',
                                backgroundColor: '#fff'
                            }}>
                            {roles?.map((item, i) =>
                                <MenuItem key={item?.username}
                                          value={item?.username}>{`${item?.username}`}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <Grid item xs={12}>
                        <TextField value={data.password} onChange={(e) => setData({...data, password: e.target.value})}
                                   label="Пароль" type="password" variant="outlined" fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                            Вход
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Auth;
