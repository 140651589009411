import {makeAutoObservable} from "mobx";
import Judging_service from "./services/judging_service";
import {v1} from "uuid";
import {toast} from "react-toastify";
import axios from "axios";

export class JudgingStore {
    age_group_disciplines = []
    commands = []
    commandInfo = []
    scoreCommand = null
    loading = false
    status = {}
    check = {}
    loadingTable = false
    judgleStatus = []

    constructor() {
        makeAutoObservable(this)
    }

    setLoadingTable(loadingTable) {
        this.loadingTable = loadingTable
    }

    setStatusJudging(status) {
        this.status = status
    }

    setCheckJudging(check) {
        this.check = check
    }

    setAgeGroupDiscipline(age_group_disciplines) {
        this.age_group_disciplines = age_group_disciplines
    }

    setLoading(loading) {
        this.loading = loading
    }

    setAllCommand(commands) {
        this.commands = commands
    }

    setInfoCommand(commandInfo) {
        this.commandInfo = commandInfo
    }

    setScoreCommand(scoreCommand) {
        this.scoreCommand = scoreCommand
    }

    setJudgleStatus(judgleStatus) {
        this.judgleStatus = judgleStatus
    }

    async getJudgleStatus() {
        try {
            const res = await Judging_service.getJudgleStatus()
            const coach_type_4 = res.data?.filter(f => +f.coach_type_id === 4)
            const coach_type_1 = res.data?.filter(f => +f.coach_type_id === 1)
            const coach_type_3 = res.data?.filter(f => +f.coach_type_id === 3)
            const coach_type_2 = res.data?.filter(f => +f.coach_type_id === 2)
            const coach_type_5 = res.data?.filter(f => +f.coach_type_id === 5)
            this.setJudgleStatus({
                coach_type_1: coach_type_1,
                coach_type_2: coach_type_2,
                coach_type_3: coach_type_3,
                coach_type_4: coach_type_4,
                coach_type_5: coach_type_5,
            })
        } catch (e) {
            console.log(e)
        }
    }

    async startJudging(idGroup, idDiscipline, discipline_stage_id) {
        // debugger
        try {
            const res1 = await Judging_service.startJudging(idGroup, idDiscipline, discipline_stage_id)

            if (res1.data.error) {
                toast.error(res1.data.error, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            const res = await Judging_service.statusJudging()
            this.setStatusJudging(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    async movingStatusNextOrPrev(type) {
        try {
            const res1 = await Judging_service.movingStatusNextOrPrev(type)
            if (res1.data.status === 'last') {
                toast.warning('Последняя команда', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            const res = await Judging_service.statusJudging()
            this.setStatusJudging(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    async stopJudging() {
        try {
            const res1 = await Judging_service.stopJudging()
            const res = await Judging_service.statusJudging()
            this.setStatusJudging(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    async statusJudging() {
        try {
            const res = await Judging_service.statusJudging()
            this.setStatusJudging(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    async clearScore(disciplineId) {
        try {
            const res = await Judging_service.clearScore(disciplineId)
            this.setStatusJudging(res.data)
        } catch (e) {
            toast.error('Ошибка при очистки данных')
        }
    }

    async confirmConfirm(idAnswer, idCommand) {
        try {
            const res = await Judging_service.confirmConfirm(idAnswer, idCommand)
            toast.success(res.data.msg, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return res
        } catch (e) {
            console.log(e)
        }
    }

    async checkJudging(setOpenModal, id) {
        try {
            const res = await Judging_service.checkJudging(id)
            this.setCheckJudging(res.data)
            setOpenModal(true)
        } catch (e) {
            console.log(e)
        }
    }

    async doubleStartJudging(id) {
        try {
            const res1 = await Judging_service.doubleStartJudging(id)
            const res = await Judging_service.statusJudging()
            if (res1.data?.error) {
                toast.error(res1.data.error, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return
            }
            this.setStatusJudging(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    async getScoreCommand(id) {
        this.setLoadingTable(true)
        try {
            const res = await Judging_service.getScoreCommand(id)
            this.setScoreCommand(res.data)
            this.setLoadingTable(false)
        } catch (e) {
            console.log(e)
            this.setLoadingTable(false)
        }
    }

    async setClearScore(id_command, showUpdate = true) {
        showUpdate && this.setLoading(true)
        try {
            const res = await Judging_service.getScoreCommand(id_command)
            this.setScoreCommand(res.data)
            showUpdate && this.setLoading(false)
        } catch (e) {
            console.log(e)
            showUpdate && this.setLoading(false)
        } finally {
            showUpdate && this.setLoading(false)
        }
    }

    async setNewScore(id_command, id, value, showUpdate = true, idCommand) {
        showUpdate && this.setLoading(true)
        try {
            const res1 = await Judging_service.setNewScore(id, value, idCommand)
            if (res1.data.status === 'error') {
                toast.error(res1.data.reason, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            const res = await Judging_service.getScoreCommand(id_command)
            this.setScoreCommand(res.data)
            showUpdate && this.setLoading(false)
        } catch (e) {
            console.log(e)
            showUpdate && this.setLoading(false)
        } finally {
            showUpdate && this.setLoading(false)
        }
    }

    async setNewListScore(id_command, listScore, showUpdate = true, idCommand) {
        showUpdate && this.setLoading(true)
        try {
            const res1 = await Judging_service.setNewListScore(listScore, idCommand)
            if (res1.data.status === 'error') {
                toast.error(res1.data.msg, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            if (res1.data.status === "ok") {
                toast.success('Оценки сохранились', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            const res = await Judging_service.getScoreCommand(id_command)
            this.setScoreCommand(res.data)
            showUpdate && this.setLoading(false)
        } catch (e) {
            console.log(e)
            showUpdate && this.setLoading(false)
        } finally {
            showUpdate && this.setLoading(false)
        }
    }

    async getInfoCommand(id) {
        try {
            const res = await Judging_service.getInfoCommand(id)
            this.setInfoCommand(res.data?.sort((a, b) => Number(a?.is_reserve) - Number(b?.is_reserve)))
        } catch (e) {
            console.log(e)
        }
    }

    async downloadJudgle(id) {
        try {
            const res = await axios({
                url: `${process.env.REACT_APP_API_URL_PORT}results/${id}`, //your url
                method: 'GET',
                responseType: 'blob', // important
            })
            const href = URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'file.xlsx'); //or any other extension
            // link.setAttribute('download', 'file.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (e) {
            console.log(e)
        }
    }

    async getAgeGroupDiscipline() {
        try {
            const res = await Judging_service.getAgeGroupDiscipline()
            this.setAgeGroupDiscipline(res.data.map((el) => ({...el, id: v1()})))
        } catch (e) {
            console.log(e)
        }
    }

    async getAllCommand(id_group, id_disciplin, idDisciplineStage) {
        try {
            const res = await Judging_service.getAllCommand(id_group, id_disciplin, idDisciplineStage)
            this.setAllCommand(res.data.sort((a, b) => a.competition_draw - b.competition_draw))
        } catch (e) {
            console.log(e)
        }
    }
}
